export const A11Y_TABINDEX = val => ['tabindex', val];
export const A11Y_TREEGRID = () => ['role', 'treegrid'];
export const A11Y_PRESENTATION = () => ['role', 'presentation'];
export const A11Y_GRIDCELL = () => ['role', 'gridcell'];
export const A11Y_ROWHEADER = () => ['role', 'rowheader'];
export const A11Y_ROWGROUP = () => ['role', 'rowgroup'];
export const A11Y_COLUMNHEADER = () => ['role', 'columnheader'];
export const A11Y_ROW = () => ['role', 'row'];
export const A11Y_MENU = () => ['role', 'menu'];
export const A11Y_MENU_ITEM = () => ['role', 'menuitem'];
export const A11Y_MENU_ITEM_CHECKBOX = () => ['role', 'menuitemcheckbox'];
export const A11Y_COMBOBOX = () => ['role', 'combobox'];
export const A11Y_LISTBOX = () => ['role', 'listbox'];
export const A11Y_OPTION = () => ['role', 'option'];
export const A11Y_CHECKBOX = () => ['role', 'checkbox'];
export const A11Y_SCOPE_COL = () => ['scope', 'col'];
export const A11Y_SCOPE_ROW = () => ['scope', 'row'];
export const A11Y_TEXT = () => ['type', 'text'];
export const A11Y_LABEL = val => ['aria-label', val];
export const A11Y_HIDDEN = () => ['aria-hidden', 'true'];
export const A11Y_DISABLED = () => ['aria-disabled', 'true'];
export const A11Y_MULTISELECTABLE = () => ['aria-multiselectable', 'true'];
export const A11Y_HASPOPUP = val => ['aria-haspopup', val];
export const A11Y_ROWCOUNT = val => ['aria-rowcount', val];
export const A11Y_COLCOUNT = val => ['aria-colcount', val];
export const A11Y_ROWINDEX = val => ['aria-rowindex', val];
export const A11Y_COLINDEX = val => ['aria-colindex', val];
export const A11Y_EXPANDED = val => ['aria-expanded', val];
export const A11Y_SORT = val => ['aria-sort', val];
export const A11Y_READONLY = () => ['aria-readonly', 'true'];
export const A11Y_INVALID = () => ['aria-invalid', 'true'];
export const A11Y_CHECKED = val => ['aria-checked', val];
export const A11Y_SELECTED = () => ['aria-selected', 'true'];
export const A11Y_AUTOCOMPLETE = () => ['aria-autocomplete', 'list'];
export const A11Y_CONTROLS = val => ['aria-controls', val];
export const A11Y_ACTIVEDESCENDANT = val => ['aria-activedescendant', val];
export const A11Y_LIVE = val => ['aria-live', val];
export const A11Y_RELEVANT = val => ['aria-relevant', val];
export const A11Y_SETSIZE = val => ['aria-setsize', val];
export const A11Y_POSINSET = val => ['aria-posinset', val];