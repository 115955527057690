import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as Highcharts from 'highcharts';
import { Subject, tap } from 'rxjs';
import { FORWARD_TRADE_HUPX_CHART_OPTIONS, previousAuctions, updateAuctionType } from '../../../limit-order/constants';
import { AllowedAuction } from '../../../../shared/enums';
import { MarketPriceType } from '../../../limit-order/enums';

@Component({
  selector: 'app-forward-trade-hupx-chart',
  templateUrl: './forward-trade-hupx-chart.component.html',
  styleUrls: ['./forward-trade-hupx-chart.component.scss'],
  standalone: false,
})
export class ForwardTradeHupxChartComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  /** The selected auction type. */
  @Input()
  public auctionType!: AllowedAuction | null;

  /** Hour labels to define the X axis label texts. */
  @Input()
  public hourLabels: string[] = [];

  /** Signals changes to the chart. */
  @Input()
  public changes:
    | Subject<{
        minimum: {
          hupxPosition: number;
          forwardTradeQuantity: number | null;
          summation: number;
        }[];
        maximum: {
          hupxPosition: number;
          forwardTradeQuantity: number | null;
          summation: number;
        }[];
      }>
    | undefined;

  /** Checks if we are using Forward Trades for the chart title. */
  @Input()
  public usingForwardTrades: boolean = true;

  @Input()
  public hideForwardTrades: boolean = false;

  /** The selected market price type. */
  @Input()
  public marketPriceType: MarketPriceType | undefined;

  /** The current-previous auction pairs. */
  private previousAuctions = previousAuctions;

  public highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options = FORWARD_TRADE_HUPX_CHART_OPTIONS;
  private chartRef: Highcharts.Chart | undefined;
  public chartCallback: Highcharts.ChartCallbackFunction = chart => {
    this.chartRef = chart;
  };

  /** Used by HighchartJS angular wrapper to signal changes. */
  public updateFlag = false;

  public ngOnInit(): void {
    this.changes
      ?.pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(change => {
          this.generateChart(change);
        })
      )
      .subscribe();
  }

  /** Generates xAxes and default data for the chart. */
  private generateChart(values: {
    minimum: {
      hupxPosition: number;
      forwardTradeQuantity: number | null;
      summation: number;
    }[];
    maximum: {
      hupxPosition: number;
      forwardTradeQuantity: number | null;
      summation: number;
    }[];
  }) {
    this.chartOptions.title = { text: 'Hourly positions' };

    this.chartOptions.xAxis = {
      ...this.chartOptions.xAxis,
      categories: this.hourLabels,
      max: this.hourLabels.length - 1,
    };

    this.chartOptions.series =
      this.marketPriceType === MarketPriceType.Predict
        ? [
            {
              type: 'line',
              step: 'center',
              name: 'Minimum Net (line)',
              data: values.minimum.map(value => value.summation),
              color: '#E73223',
            },
            {
              type: 'line',
              step: 'center',
              name: 'Maximum Net (line)\n',
              data: values.maximum.map(value => value.summation),
              color: '#E73223',
            },
            {
              type: 'column',
              name: `${this.auctionType} Minimum FC`,
              data: values.minimum.map(value => value.hupxPosition),
              color: '#F8D25E',
            },
            {
              type: 'column',
              name: `${this.auctionType} Maximum FC`,
              data: values.maximum.map(value => value.hupxPosition),
              color: '#E5AF0A',
            },
          ]
        : [
            {
              type: 'line',
              step: 'center',
              name: 'Net',
              data: values.minimum.map(value => value.summation),
              color: '#E73223',
            },
            {
              type: 'column',
              name: `${this.auctionType} FACT`,
              data: values.minimum.map(value => value.hupxPosition),
              color: '#F8D25E',
            },
          ];

    if (!this.hideForwardTrades) {
      this.chartOptions.series.push({
        type: 'column',
        name: 'FWD',
        data: values.minimum.map(value => value.forwardTradeQuantity),
        color: '#455783',
      });
    }

    updateAuctionType(
      this.marketPriceType === MarketPriceType.Previous
        ? (this.previousAuctions.get(this.auctionType as AllowedAuction) as AllowedAuction)
        : (this.auctionType as AllowedAuction)
    );

    this.updateFlag = true;
    this.chartRef?.reflow();
  }
}
