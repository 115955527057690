import { Component, Input } from '@angular/core';

/**
 * Layout component with parts:
 * - toolbar
 * - header
 * - primary action button
 * - loading progressbar
 * - default content
 */
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  standalone: false,
})
export class MainLayoutComponent {
  /** Adds extra padding to content */
  @Input()
  enableContentSpacing = true;

  /** Indicates progressbar in toolbar */
  @Input()
  loading: boolean | null = false;
}
