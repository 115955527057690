import { Pipe, PipeTransform } from '@angular/core';
import { ScheduleType } from '../../../shared/enums/schedule-type.enum';

/**
 * Converts the given schedule type enum value to talkative definitions.
 * It maps:
 * - A01 -> Production
 * - A02 -> Domestic trade
 * - A04 -> Consumption
 *
 * If the given string is not matched by the known schedule types, then the original string is returned.
 */
@Pipe({
  name: 'scheduleType',
  standalone: false,
})
export class ScheduleTypePipe implements PipeTransform {
  transform(scheduleType: ScheduleType): string {
    switch (scheduleType) {
      case ScheduleType.A01:
        return 'Production';
      case ScheduleType.A02:
        return 'Domestic trade';
      case ScheduleType.A04:
        return 'Consumption';
      default:
        return scheduleType;
    }
  }
}
