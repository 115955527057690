import { Options } from 'highcharts';
import { AllowedAuction } from '../../../shared/enums';

let auctionType = AllowedAuction.Dam;

export const PRICE_CHART_OPTIONS: Options = {
  chart: {
    backgroundColor: 'transparent',
    height: '285px',
    animation: false,
  },
  xAxis: {
    title: {
      text: 'Hours',
    },
    labels: {
      formatter() {
        /**
         * this.value is the chart value label which has the pattern: `HH:MM-HH:MM [MW]`, E.g.: `00:00-01:00 [MW]`, `10:00-11:00 [MW]`, `23:00-00:00 [MW]`, etc...
         * To provide the X axis labels for the chart in the range 1-24 we need to grab the first hour from the value label and increment it by 1.
         */
        return (parseInt(this.value.toString().substring(0, 2), 10) + 1).toString();
      },
    },
    reversedStacks: true,
  },
  yAxis: {
    title: {
      text: 'EUR/MWh',
    },
  },
  tooltip: {
    shared: true,
    formatter(s) {
      const x = (this as unknown as { point: { x: number } }).point.x;
      let finalText = `<b>${auctionType} price for ${this.key?.toString().slice(0, -5)}</b><br/>${this.series.name}: ${
        this.y
      } EUR/MWh<br/>`;
      if (s.chart.series[1]) {
        finalText += `Prediction range: ${s.chart.series[1].data[x].high! - s.chart.series[0].data[x].y!} EUR/MWh<br/>`;
      }
      return finalText;
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: false,
    },
  },
  credits: {
    enabled: false,
  },
};

export function updateAuctionType(auction: AllowedAuction): void {
  auctionType = auction;
}
