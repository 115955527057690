import ClipboardData from "./clipboardData.mjs";
/**
 * @private
 */
class PasteEvent {
  constructor() {
    this.clipboardData = new ClipboardData();
  }
  preventDefault() {}
}
export { PasteEvent as default };