export enum CustomScheduleScenario {
  Custom = 'Custom',
  AlteoRetailConsumption = 'ALTEO Retail cons.',
  SekKePodProduction = 'SEK KE POD prod.',
  SekAszkPodProduction = 'SEK ASZK POD prod.',
  KatDomestic = 'KAT domestic',
  ExternalPod = 'External POD',
  ExternalPartner = 'External partner',
  Alteo = 'Alteo',
  None = 'None',
}
