import { Options } from 'highcharts';

export const FORWARD_TRADE_HUPX_CHART_OPTIONS: Options = {
  chart: {
    backgroundColor: 'transparent',
    height: '285px',
    animation: false,
  },
  xAxis: {
    title: {
      text: 'Hours',
    },
    labels: {
      formatter() {
        /**
         * this.value is the chart value label which has the pattern: `HH:MM-HH:MM [MW]`, E.g.: `00:00-01:00 [MW]`, `10:00-11:00 [MW]`, `23:00-00:00 [MW]`, etc...
         * To provide the X axis labels for the chart in the range 1-24 we need to grab the first hour from the value label and increment it by 1.
         */
        return (parseInt(this.value.toString().substring(0, 2), 10) + 1).toString();
      },
    },
    reversedStacks: true,
  },
  yAxis: {
    title: {
      text: 'MW',
    },
  },
  plotOptions: {
    series: {
      animation: false,
    },
    column: {
      stacking: 'normal',
    },
  },
  tooltip: {
    formatter() {
      return `<b>${this.x?.toString().slice(0, -5)}</b><br/>${this.series.name}: ${this.y} MW<br/>`;
      /** TODO the total has been disabled by Alteos request.
       * if (s.chart.hoverPoint?.total) {
       *   formattedText += `Total: ${s.chart.hoverPoint?.total} MW<br/>`;|
       * }
       */
    },
  },
  credits: {
    enabled: false,
  },
};
