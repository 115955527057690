import { Component, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { DomesticTradeSchedulesTableData, SchedulesTableData } from '../../interfaces';

@Component({
  selector: 'app-schedules-table',
  templateUrl: './schedules-table.component.html',
  styleUrls: ['./schedules-table.component.scss'],
  standalone: false,
})
export class SchedulesTableComponent {
  @Input()
  public schedules: (SchedulesTableData | DomesticTradeSchedulesTableData)[] = [];

  @Input()
  public insufficientFilters = true;

  @Input()
  public currentPodScheduleNotExists = false;

  @Input()
  public isProductionSchedule = false;

  public readonly displayedColumns: string[] = ['timeBlock', 'currentSchedule', 'scheduleAfterTrade'];

  constructor(private readonly decimalPipe: DecimalPipe) {}

  public labelOf(columnName: string): string {
    switch (columnName) {
      case 'timeBlock':
        return 'Time block (local time)';
      case 'currentSchedule':
        return `Current ${this.isProductionSchedule ? 'production schedule' : 'schedule'}`;
      case 'scheduleAfterTrade':
        return `${this.isProductionSchedule ? 'Production schedule' : 'Schedule'} after successful trade`;
      default:
        return columnName;
    }
  }

  public getCellValue(element: SchedulesTableData, idx: number, column: string): string {
    switch (column) {
      case 'timeBlock':
        return element.timeBlock;
      case 'currentSchedule':
        return `${this.decimalPipe.transform(element.currentSchedule, '1.0-3', 'hu')} MW`;
      case 'scheduleAfterTrade':
        return `${this.decimalPipe.transform(element.scheduleAfterTrade, '1.0-3', 'hu')} MW`;
      default:
        return '';
    }
  }
}
