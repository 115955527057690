/**
 * It defines the available limit order fallback modes.
 * These are different strategies to handle missing limit orders when the filing deadline has passed.
 */
export enum LimitOrderFallbackMode {
  /** Automatically save a limit order, where the step 1 price is 0 and all step 1 and step 1.1 quantities are 0. */
  ZeroFilled = 'zeroFilled',
  /** Automatically save a limit order, which is a copy of the previous day. */
  PreviousDay = 'previousDay',
  /** Automatically save a limit order, which is a copy of the same day from the previous week (D-7). */
  PreviousWeek = 'previousWeek',
}
