import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import 'highcharts/highcharts-more';
import { Subject, tap } from 'rxjs';
import { previousAuctions, PRICE_CHART_OPTIONS } from '../../../limit-order/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MarketPrice } from '../../interfaces/responses';
import { MarketPriceType } from '../../../limit-order/enums';
import { AllowedAuction } from '../../../../shared/enums';

@Component({
  selector: 'app-price-chart',
  templateUrl: './price-chart.component.html',
  styleUrls: ['./price-chart.component.scss'],
  standalone: false,
})
export class PriceChartComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  /** The selected auction type. */
  @Input()
  public auctionType!: AllowedAuction | null;

  /** Hour labels to define the X axis label texts. */
  @Input()
  public hourLabels: string[] = [];

  /** Signals changes to the chart. */
  @Input()
  public changes: Subject<{ marketPrice: MarketPrice[]; marketPriceType: MarketPriceType } | undefined> | undefined;

  public highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options = PRICE_CHART_OPTIONS;
  private chartRef: Highcharts.Chart | undefined;
  public chartCallback: Highcharts.ChartCallbackFunction = chart => {
    this.chartRef = chart;
  };

  /** Used by HighchartJS angular wrapper to signal changes. */
  public updateFlag = false;

  /** The available market price types. */
  public marketPriceTypes = MarketPriceType;

  /** The selected market price type. */
  private marketPriceType: MarketPriceType | undefined;

  /** The current-previous auction pairs. */
  private previousAuctions = previousAuctions;

  public ngOnInit(): void {
    this.changes
      ?.pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(change => {
          this.marketPriceType = change?.marketPriceType;

          if (change?.marketPriceType === MarketPriceType.None) {
            return;
          }

          this.generateChart(change);
        })
      )
      .subscribe();
  }

  /** Generates xAxes and default data for the chart. */
  private generateChart(quantityConstraints?: { marketPrice: MarketPrice[]; marketPriceType: MarketPriceType }) {
    const marketType = quantityConstraints?.marketPriceType;

    this.chartOptions.title = {
      text:
        marketType === MarketPriceType.Fact
          ? `DA ${this.auctionType}`
          : marketType === MarketPriceType.Previous
            ? `DA ${previousAuctions.get(this.auctionType as AllowedAuction)}`
            : 'DA FC',
    };

    this.chartOptions.xAxis = {
      ...this.chartOptions.xAxis,
      categories: this.hourLabels,
      max: this.hourLabels.length - 1,
    };

    this.chartOptions.series = [
      {
        name: `${
          this.marketPriceType === MarketPriceType.Previous
            ? (this.previousAuctions.get(this.auctionType as AllowedAuction) as AllowedAuction)
            : (this.auctionType as AllowedAuction)
        } price`,
        data: quantityConstraints?.marketPrice.map(mp => mp.price),
        type: 'line',
        color: '#DF8343',
      },
    ];

    if (quantityConstraints?.marketPriceType === MarketPriceType.Predict) {
      /** The series type `arearange` cannot be found after registering highcharts-more module. */
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.chartOptions.series.push({
        name: 'Range',
        data: quantityConstraints?.marketPrice.map(mp => [mp.price! - mp.range!, mp.price! + mp.range!]),
        lineWidth: 0,
        linkedTo: ':previous',
        color: '#FA8111',
        fillOpacity: 0.3,
        type: 'arearange',
        zIndex: 0,
        marker: {
          enabled: false,
        },
      });
    }

    this.updateFlag = true;
    this.chartRef?.reflow();
  }

  protected readonly queueMicrotask = queueMicrotask;
}
