import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { HelpDrawerService } from '../../../../../services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-help-drawer-container',
  templateUrl: './help-drawer-container.component.html',
  styleUrls: ['./help-drawer-container.component.scss'],
  standalone: false,
})
export class HelpDrawerContainerComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawer!: MatDrawer;

  @Input()
  public pdfName!: string;
  public pdfUrl!: SafeResourceUrl;

  constructor(
    public helpDrawerService: HelpDrawerService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.constructUrl();
    this.helpDrawerService.setSidenav(this.drawer);
  }

  private constructUrl() {
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/wiki-documents/${this.pdfName}`);
  }
}
