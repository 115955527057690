import { Component } from '@angular/core';
import { HelpDrawerService } from '../../../../services';

@Component({
  selector: 'app-help-drawer-button',
  templateUrl: './help-drawer-button.component.html',
  styleUrls: ['./help-drawer-button.component.scss'],
  standalone: false,
})
export class HelpDrawerButtonComponent {
  constructor(public readonly helpDrawerService: HelpDrawerService) {}
}
