/* eslint-disable quote-props,
                  @typescript-eslint/naming-convention */
export const TRADE_SCENARIOS = new Map(
  Object.entries({
    '0': 'Custom',
    '1': 'ALTEO Retail cons.',
    '2': 'SEK ASZK POD prod.',
    '3': 'SEK KE POD prod.',
    '4': 'External POD',
    '5': 'KAT domestic',
    '6': 'External partner',
    '7': 'Alteo',
  })
);
