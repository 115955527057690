import { Component, DestroyRef, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BossStateModuleEnum, SyncIntervalEnum } from '../../enums';
import { filter, Observable, tap, merge } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Component({
  templateUrl: './sync-dialog.component.html',
  styleUrls: ['./sync-dialog.component.scss'],
  standalone: false,
})
export class SyncDialogComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  /** We store the sending states coming from the component that opens this dialog. */
  public sendingStates: {
    entity: string | undefined;
    enabled: boolean;
  }[] = [];

  constructor(
    public dialogRef: MatDialogRef<
      SyncDialogComponent,
      {
        sendingStates: {
          entity: string | undefined;
          enabled: boolean;
        }[];
      }
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dialogTitle: string;
      module: BossStateModuleEnum;
      syncInterval: SyncIntervalEnum;
      actionToCall: (entity: string | undefined, state: boolean) => Observable<void>;
      sendingStates: {
        entity: string | undefined;
        enabled: boolean;
      }[];
      disabled: boolean;
    }
  ) {}

  public ngOnInit(): void {
    this.sendingStates = [...this.data.sendingStates];

    merge(
      this.dialogRef.backdropClick(),
      this.dialogRef.keydownEvents().pipe(filter(currentEvent => currentEvent.key === 'Escape'))
    )
      .pipe(
        tap(() => {
          this.handleCancelAction();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  /** Closes the dialog. It sends back the state of the changed sending states. */
  public handleCancelAction(): void {
    this.dialogRef.close({
      sendingStates: this.sendingStates,
    });
  }

  /** Handles the toggle change. */
  public toggleHasChanged(
    $event: boolean,
    sendingState: { entity: string | undefined | null; enabled: boolean }
  ): void {
    sendingState.enabled = $event;
  }
}
