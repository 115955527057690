/* eslint-disable @typescript-eslint/naming-convention */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiResponse } from '../../../shared/interfaces';
import {
  EdaPricingStrategyDetailsResponse,
  EdaPricingStrategyHeadResponse,
  EdaOfferReportHeadResponse,
  EdaOfferReportDetailsResponse,
  EdaConfigurationsResponsePayload,
  EdaPricingStrategyResponseMeta,
  EdaOfferReportHeadResponseMeta,
} from '../interfaces/responses';
import { filterOutNullishProperties } from '../../../shared/utils';
import { EntityEnum, AllowedDirection, AllowedReserveType } from '../enums';
import { ModifyEdaConfigurationPayload } from '../interfaces/requests';
import { formatInBudapestTimeZone } from '../../../shared/utils/dates';

@Injectable({ providedIn: 'root' })
export class EdaPricingStrategyService {
  constructor(private readonly http: HttpClient) {}

  /**
   * Loads the meta information about available versions for the given day and entity.
   */
  public listVersionsForDayAndEntity(params: {
    date: Date;
    entity: string;
    tenantIdentifier: string;
  }): Observable<ApiResponse<EdaPricingStrategyHeadResponse[], EdaPricingStrategyResponseMeta>> {
    return this.http.get<ApiResponse<EdaPricingStrategyHeadResponse[], EdaPricingStrategyResponseMeta>>(
      `${environment.apiBasePath}/v1/eda`,
      {
        params: {
          date: formatInBudapestTimeZone(params.date, 'yyyy-MM-dd'),
          entity: params.entity,
          tenant: params.tenantIdentifier,
        },
      }
    );
  }

  /**
   * Loads the details of the selected pricing strategy.
   * The details endpoint contains the calculation parameter and selected strategy for
   * each hour in the selected month.
   */
  public loadDetails(id: number): Observable<EdaPricingStrategyDetailsResponse> {
    return this.http
      .get<ApiResponse<EdaPricingStrategyDetailsResponse>>(`${environment.apiBasePath}/v1/eda/${id}`)
      .pipe(map(response => response?.payload));
  }

  /**
   * Loads the details of the selected pricing strategy.
   * The details endpoint contains the calculation parameter and selected strategy for
   * each hour in the selected day.
   */
  public save(
    strategy: Omit<EdaPricingStrategyDetailsResponse, 'id' | 'version' | 'createdAt' | 'createdBy' | 'createdByUser'>,
    latestKnownVersion?: number
  ): Observable<EdaPricingStrategyDetailsResponse> {
    return this.http
      .post<ApiResponse<EdaPricingStrategyDetailsResponse>>(`${environment.apiBasePath}/v1/eda`, strategy, {
        params: filterOutNullishProperties({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          latest_known_version: latestKnownVersion,
        }),
      })
      .pipe(map(response => response?.payload));
  }

  /**
   * Updates the BOSs sending state.
   *
   * @param entity The selected EDA entity.
   * @param state The state with which we want to update the current one.
   */
  public sendBossSendingState(entity: string, state: boolean): Observable<void> {
    return this.http.post<void>(`${environment.apiBasePath}/v1/eda/sending-state`, { state, entity });
  }

  /**
   * This endpoint returns with the EDA configuration.
   *
   * @param tenant The optional unique identifier of the selected tenant.
   *
   * @returns EDA configuration values.
   */
  public getEdaConfiguration(tenant?: string): Observable<EdaConfigurationsResponsePayload> {
    return this.http
      .get<
        ApiResponse<EdaConfigurationsResponsePayload>
      >(`${environment.apiBasePath}/v1/eda/configuration`, { params: filterOutNullishProperties({ tenant }) })
      .pipe(map(response => response?.payload));
  }

  /**
   * This endpoint partially updates the EDA configuration with the specified payload.
   * The authenticated user email is saved in the update_by column during an update.
   *
   * @param configuration Partial configuration object.
   * @param tenant The unique identifier of the selected tenant.
   *
   * @returns The full EDA configuration response.
   */
  public modifyEdaConfiguration(
    configuration: ModifyEdaConfigurationPayload,
    tenant: string
  ): Observable<EdaConfigurationsResponsePayload> {
    return this.http
      .patch<
        ApiResponse<EdaConfigurationsResponsePayload>
      >(`${environment.apiBasePath}/v1/eda/configuration`, configuration, { params: { tenant } })
      .pipe(map(response => response?.payload));
  }

  /**
   * Copies a strategy to a selected range.
   *
   * @param id The ID of the selected strategy from which we want to copy.
   * @param params It contains the selected start and end date and the latest known version.
   */
  public copyEdaPricingStrategyValues(
    id: number,
    params: { fromDate: string; toDate: string; latestKnownVersion: number }
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.apiBasePath}/v1/eda/copy/${id}`,
      {},
      {
        params: {
          from_date: params.fromDate,
          to_date: params.toDate,
          latest_known_version: params.latestKnownVersion,
        },
      }
    );
  }

  /**
   * Lists EDA offer report heads.
   *
   * @param date The date of the EDA offer report.
   * @param tenant The unique identifier of the selected tenant.
   */
  public listEdaOfferReportHeads(
    date: Date,
    tenant: string
  ): Observable<ApiResponse<EdaOfferReportHeadResponse[], EdaOfferReportHeadResponseMeta>> {
    return this.http.get<ApiResponse<EdaOfferReportHeadResponse[], EdaOfferReportHeadResponseMeta>>(
      `${environment.apiBasePath}/v1/eda/report`,
      {
        params: { date: formatInBudapestTimeZone(date, 'yyyy-MM-dd'), tenant },
      }
    );
  }

  /**
   * Lists EDA offer detailed reports.
   *
   * @param params Contains the ID, the direction, the reserve type, the tenant identifier and the optional entity.
   */
  public listEdaOfferReportDetails(params: {
    id: number;
    date: Date;
    direction: AllowedDirection;
    reserveType: AllowedReserveType;
    tenantIdentifier: string;
    entity?: EntityEnum;
  }): Observable<EdaOfferReportDetailsResponse[]> {
    return this.http
      .get<ApiResponse<EdaOfferReportDetailsResponse[]>>(`${environment.apiBasePath}/v1/eda/report/${params.id}`, {
        params: filterOutNullishProperties({
          date: formatInBudapestTimeZone(params.date, 'yyyy-MM-dd'),
          direction: params.direction,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          reserve_type: params.reserveType,
          tenant: params.tenantIdentifier,
          entity: params?.entity,
        }),
      })
      .pipe(map(response => response?.payload));
  }
}
