/**
 * Alteo could provide forecasted (predicted) prices for the market and until the
 * official market price is unavailable the price type will be `Predict`. After
 * the official price is available the price type will be `Fact`. If the auction type
 * is `IDA` and there is no alteo position for this type, the price type will be `Previous`.
 */
export enum MarketPriceType {
  Predict = 'Predict',
  Fact = 'Fact',
  None = 'None',
  Previous = 'Previous',
}
