/* eslint-disable @typescript-eslint/naming-convention */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiResponse, ConsumptionPod, ProductionPod } from '../shared/interfaces';
import { environment } from '../../environments/environment';
import {
  OrderEntryPayload,
  OwnTradeHistoryQueryParameters,
  TradeHistoryQueryParameters,
} from '../shared/interfaces/requests';
import { OrderEntrySide, ScheduleType } from '../shared/enums';
import {
  OrderEntryResponsePayload,
  OrderPriceCalculationResponsePayload,
  OrderValidationRuleResponsePayload,
  PodResponse,
  PublicOrderBookResponsePayload,
  TradeHistoryResponseMeta,
  TradeHistoryResponsePayload,
} from '../shared/interfaces/responses';
import { filterOutNullishProperties } from '../shared/utils';

@Injectable()
export class HupxService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   * Returns calculated price for the given contractId, order side and quantity.
   *
   * This endpoint is only available for users with {@link Role.HupxExternalPartner} role as external partners
   * are only allowed to create orders with calculated prices.
   *
   * @param params The query parameters:
   *  - contractId: Contract id to make offer by.
   *  - orderSide: Order side.
   *  - quantity: Quantity to make offer by.
   */
  public getCalculatedOrderPrice(params: {
    contractId: number;
    side: OrderEntrySide;
    quantity: number;
  }): Observable<OrderPriceCalculationResponsePayload> {
    return this.httpClient
      .get<ApiResponse<OrderPriceCalculationResponsePayload>>(
        `${environment.apiBasePath}/v1/hupx/order-price-calculation`,
        {
          params: {
            contractId: params.contractId,
            side: params.side,
            quantity: params.quantity,
          },
        }
      )
      .pipe(map(response => response?.payload));
  }

  /**
   * Gives all order validation rule from HUPX.
   */
  public getOrderValidationRules(): Observable<OrderValidationRuleResponsePayload[]> {
    return this.httpClient
      .get<
        ApiResponse<OrderValidationRuleResponsePayload[]>
      >(`${environment.apiBasePath}/v1/hupx/order/validation-rules`)
      .pipe(map(response => response?.payload));
  }

  /**
   * Get public order book endpoint query parameters.
   *
   * @param top Limit the number of best offers returned.
   */
  public getPublicOrderBook(top?: number): Observable<PublicOrderBookResponsePayload[]> {
    return this.httpClient
      .get<ApiResponse<PublicOrderBookResponsePayload[]>>(`${environment.apiBasePath}/v1/hupx/public-order-book`, {
        params: filterOutNullishProperties({
          top,
        }),
      })
      .pipe(map(response => response?.payload));
  }

  /**
   * Creates order request in HUPX.
   *
   * @param tenant Tenant identifier of the order.
   * @param order Order payload.
   */
  public orderEntry(tenant: string, order: OrderEntryPayload): Observable<ApiResponse<OrderEntryResponsePayload>> {
    return this.httpClient.post<ApiResponse<OrderEntryResponsePayload>>(
      `${environment.apiBasePath}/v1/hupx/orders/order-entry`,
      order,
      {
        params: {
          tenant,
        },
      }
    );
  }

  /**
   * Fetch trade history from HUPX.
   *
   * @param params filter options.
   */
  public orderHistory(
    params: TradeHistoryQueryParameters
  ): Observable<ApiResponse<TradeHistoryResponsePayload[], TradeHistoryResponseMeta>> {
    return this.httpClient
      .get<ApiResponse<TradeHistoryResponsePayload[], TradeHistoryResponseMeta>>(
        `${environment.apiBasePath}/v1/hupx/orders/history`,
        {
          params: filterOutNullishProperties({
            startDay: params.startDay,
            endDay: params.endDay,
            user_id: params.userId,
            side: params.side,
            scenario: params.scenario,
            tenant_filter: params.tenantFilter,
            offset: params.offset,
            limit: params.limit,
            tenant: params.tenant,
            collapse_orders: params.collapseOrders,
          }),
        }
      )
      .pipe(map(response => response));
  }

  /**
   * Fetch trade history from HUPX.
   *
   * @param params filter options.
   */
  public ownOrderHistory(
    params: OwnTradeHistoryQueryParameters
  ): Observable<ApiResponse<TradeHistoryResponsePayload[], TradeHistoryResponseMeta>> {
    return this.httpClient.get<ApiResponse<TradeHistoryResponsePayload[], TradeHistoryResponseMeta>>(
      `${environment.apiBasePath}/v1/hupx/orders/personal-history`,
      {
        params: filterOutNullishProperties({
          startDay: params.startDay,
          endDay: params.endDay,
          side: params.side,
          scenario: params.scenario,
          collapse_orders: params.collapseOrders,
          limit: params.limit,
          offset: params.offset,
          tenant: params.tenant,
        }),
      }
    );
  }

  /**
   * Lists all PODs belong to the tenant (plus the tenant's EiC code), grouped by their schedule type.
   *
   * @param tenantIdentifier The unique identifier of the tenant.
   * @param date The optional selected date in `yyyy-MM-dd` format.
   */
  public listGroupedPodsForTenantWithTenantEic(
    tenantIdentifier: string,
    date?: string
  ): Observable<{
    tenantEic: string;
    externalAlteoTrading: boolean;
    productionPods: ProductionPod[];
    consumptionPods: ConsumptionPod[];
  }> {
    return this.httpClient
      .get<
        ApiResponse<PodResponse[], { tenantEic: string; externalAlteoTrading: boolean }>
      >(`${environment.apiBasePath}/v1/hupx/external-order/pods`, { params: filterOutNullishProperties({ tenant: tenantIdentifier, date: date }) })
      .pipe(
        map(pods => {
          const productionPods: ProductionPod[] = [];
          const consumptionPods: ConsumptionPod[] = [];

          pods.payload.forEach(pod => {
            if (pod.scheduleType === ScheduleType.A01) {
              productionPods.push(pod as ProductionPod);
            } else if (pod.scheduleType === ScheduleType.A04) {
              consumptionPods.push(pod as ConsumptionPod);
            }
          });

          return {
            productionPods,
            consumptionPods,
            tenantEic: pods.meta.tenantEic,
            externalAlteoTrading: pods.meta.externalAlteoTrading,
          };
        })
      );
  }
}
