import { Component, Input, OnInit } from '@angular/core';
import { DomesticTradeSchedulesTableData, SchedulesTableData } from '../../interfaces';
import { OrderEntrySide } from '../../../../shared/enums';

@Component({
  selector: 'app-domestic-schedules-table',
  templateUrl: './domestic-schedules-table.component.html',
  styleUrls: ['./domestic-schedules-table.component.scss'],
  standalone: false,
})
export class DomesticSchedulesTableComponent implements OnInit {
  /**
   * The schedule data for the table.
   */
  @Input()
  public schedules: (SchedulesTableData | DomesticTradeSchedulesTableData)[] = [];

  /**
   * checks if the filters belong to schedule table are appropriate
   */
  @Input()
  public insufficientFilters = true;

  /**
   * Checks if the schedules for the selected partner is exist.
   */
  @Input()
  public currentPartnerScheduleNotExists = false;

  /**
   * Clarification for table header.
   */
  @Input()
  public sekToPartner: string | undefined = '';

  @Input()
  public partnerToSek: string | undefined = '';

  /**
   * The trade side.
   */
  @Input()
  public side: OrderEntrySide | undefined;

  public firstCurrentScheduleLabel = 'SEK_TO_PARTNER';
  public firstScheduleAfterTradeLabel = 'SEK_TO_PARTNER';
  public secondCurrentScheduleLabel = 'PARTNER_TO_SEK';
  public secondScheduleAfterTradeLabel = 'PARTNER_TO_SEK';

  public readonly displayedColumns: string[] = [
    'timeBlock',
    'currentScheduleSekToPartner',
    'scheduleAfterTradeSekToPartner',
    'currentSchedulePartnerToSek',
    'scheduleAfterTradePartnerToSek',
  ];

  ngOnInit(): void {
    this.firstCurrentScheduleLabel =
      this.side === OrderEntrySide.Sell
        ? (this.sekToPartner ?? 'SEK_TO_PARTNER')
        : (this.partnerToSek ?? 'PARTNER_TO_SEK');
    this.firstScheduleAfterTradeLabel =
      this.side === OrderEntrySide.Sell
        ? (this.sekToPartner ?? 'SEK_TO_PARTNER')
        : (this.partnerToSek ?? 'PARTNER_TO_SEK');
    this.secondCurrentScheduleLabel =
      this.side === OrderEntrySide.Sell
        ? (this.partnerToSek ?? 'PARTNER_TO_SEK')
        : (this.sekToPartner ?? 'SEK_TO_PARTNER');
    this.secondScheduleAfterTradeLabel =
      this.side === OrderEntrySide.Sell
        ? (this.partnerToSek ?? 'PARTNER_TO_SEK')
        : (this.sekToPartner ?? 'SEK_TO_PARTNER');
  }
}
